import { useMemo } from 'react'
import { RaIcon } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'

import styles from './ra-illustration.module.scss'

interface RaIllustrationProps {
  index: number
  color?: 'light' | 'dark' | 'primary'
}

export const RaIllustration = ({
  index,
  color = 'primary',
}: RaIllustrationProps) => {
  const variant = useMemo(() => {
    switch (true) {
      case index % 3 === 0:
        return (
          <RaIcon
            color={color}
            icon={ICONS.HEADING_ILLUSTRATION_3}
          />
        )

      case index % 2 === 0:
        return (
          <RaIcon
            color={color}
            icon={ICONS.HEADING_ILLUSTRATION_2}
          />
        )

      default:
        return (
          <RaIcon
            color={color}
            icon={ICONS.HEADING_ILLUSTRATION_1}
          />
        )
    }
  }, [index])

  return <div className={styles['ra-illustration']}>{variant}</div>
}
