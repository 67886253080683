'use client'
import { isServer } from '@utilities/helpers/core.helpers'

import { CartContextProvider, useCart } from '@contexts/cart.context'
import {
  SearchPostsProvider,
  useSearchPosts,
} from '@contexts/search-posts.context'
import {
  SettingsContextProvider,
  useSettings,
} from '@contexts/settings.context'
import { UiContextProvider, useUi } from '@contexts/ui.context'
import { UserContextProvider, useUser } from '@contexts/user.context'

// Combine Context Components (or Providers) util
export const CombineContextComponents = ({ initial, children }) => {
  return (
    <UiContextProvider>
      <CartContextProvider
        GTM_ID={initial.GTM_ID}
        destination={initial.site}
        dialogLabels={{
          rt: initial?.settings?.popup_rt,
          bb: initial?.settings?.popup_bb,
        }}>
        <SettingsContextProvider initial={initial}>
          <UserContextProvider
            GTM_ID={initial.GTM_ID}
            userData={initial.userData}>
            <SearchPostsProvider>{children}</SearchPostsProvider>
          </UserContextProvider>
        </SettingsContextProvider>
      </CartContextProvider>
    </UiContextProvider>
  )
}

// Context Providers collection
export const RNXTMultiContextCollection = {
  cart: CartContextProvider,
  ui: UiContextProvider,
  settings: SettingsContextProvider,
  user: UserContextProvider,
  searchPosts: SearchPostsProvider,
}

// Context Stores collection
export const RNXTMultiContextStoreCollection = {
  cart: useCart,
  settings: useSettings,
  ui: useUi,
  user: useUser,
  searchPosts: useSearchPosts,
}

export const RNXTContextProvider = ({ initial, children }) => {
  if (!isServer) {
    const base_url = initial?.rest_url

    if (base_url) {
      ;(window as any).base_url = base_url.replace('wp-json/', 'wp-json')
    }
  }

  return (
    <CombineContextComponents initial={initial}>
      {children}
    </CombineContextComponents>
  )
}

RNXTContextProvider.displayName = 'RNXTContextProvider'
