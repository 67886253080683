export const defaultFields = [
  'content',
  'guid',
  'id',
  'link',
  'slug',
  'title',
  'type',
  'featured_image',
  'featured_media',
]

export const acfEssentials = (type: 'roundtrip' | 'buildingblock') => {
  const prefix = type === 'roundtrip' ? 'rt' : 'bb'
  return [
    `${prefix}_gid`,
    `${prefix}_subline`,
    `${prefix}_teaser_headline`,
    `${prefix}_keyfacts_repeater`,
    `${prefix}_teaser_text`,
    `${prefix}_day_to_day_repeater`,
    `${prefix}_additional_services_repeater`,
    `${prefix}_location`,
    `${prefix}_category`,
    `${prefix}_category_name`,
    `${prefix}_modul_teaser_gallery`,
  ].map(i => `acf.${i}`)
}

export const essentialProductFields = (type: 'roundtrip' | 'buildingblock') => {
  const acf = acfEssentials(type)
  const fields = defaultFields

  const query = fields.join(',') + ',' + acf.join(',')

  return query
}
